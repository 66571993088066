import { template as template_259ab53c61434052860d3baa926ccf1f } from "@ember/template-compiler";
const FKLabel = template_259ab53c61434052860d3baa926ccf1f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
